import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ErrorPage } from './app/pages/ErrorPage';
import { Redirect } from 'react-router-dom';
import Home from './app/pages/Home';
import SellNow from './app/pages/SellNow';
import ScheduleACall from './app/pages/ScheduleACall';
import InfoWatches from './app/pages/InfoWatches';
import CustomerData from './app/pages/CustomerData';
import About from './app/pages/About';
import CallScheduled from './app/pages/CallScheduled';

export function Routes() {
	return (
		<Switch>
			<Route path='/' exact component={Home} />
			<Route path='/sell-now' exact component={SellNow} />
			<Route path='/sell-now/watches' exact component={InfoWatches} />
			<Route path='/sell-now/customer' exact component={CustomerData} />
			<Route path='/sell-now/completed' exact component={CallScheduled} />
			<Route path='/schedule-a-call' exact component={ScheduleACall} />
			<Route path='/about-watchupgrade' exact component={About} />
			<Route path='/error' component={ErrorPage} />
			<Redirect to='/error' />
		</Switch>
	);
}
