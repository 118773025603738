import Swal from 'sweetalert2'
import '../App.css'

export const alertError = (error, customMessage) => {
	const message =
		error || customMessage || 'An error occurred while performing the operation'

	const params = {
		icon: 'error',
		title: 'Oops...',
		text: message,
		confirmButtonColor: '#000000',
	}
	Swal.fire(params).then()
}

export const alertBlock = (customMessage) => {
	const params = {
		icon: 'error',
		title: 'Error',
		text: customMessage,
		width: 600,
		padding: '3em',
		backdrop: 'rgba(0, 0, 0, 0.4)',
		allowOutsideClick: false,
		allowEscapeKey: false,
		showConfirmButton: false,
	}
	Swal.fire(params).then()
}

export const alertNoBuyers = () => {
	const params = {
		icon: 'info',
		html: `At this moment there aren't any buyers available. Please, try again after a few minutes or select <span style='font-weight: bold'>GET A VALUATION</span>.`,
		confirmButtonText: 'MENU',
		width: 600,
		padding: '3em',
		backdrop: 'rgba(0, 0, 0, 0.4)',
		allowOutsideClick: false,
		allowEscapeKey: false,
		customClass: {
			confirmButton: 'text-white black-button custom-buttonB',
		},
	}
	return Swal.fire(params).then()
}

export const alertOffclock = () => {
	const params = {
		icon: 'info',
		html: `Buyers are only available between <span style='font-weight: bold'> 8am and 17pm</span> UTC time.`,
		confirmButtonText: 'BACK',
		width: 600,
		padding: '3em',
		backdrop: 'rgba(0, 0, 0, 0.4)',
		allowOutsideClick: false,
		allowEscapeKey: false,
		customClass: {
			confirmButton: 'text-white black-button custom-buttonB',
		},
	}
	return Swal.fire(params).then()
}
