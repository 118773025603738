import React, { useState, useEffect } from 'react'
import {
	useHistory,
	useLocation,
} from 'react-router-dom/cjs/react-router-dom.min'
import AddWatchContainer from './cards/AddWatchContainer'
import { useDispatch, useSelector } from 'react-redux'
import { addWatch, updateWatch } from '../redux/actions'

export default function InfoWatches() {
	const history = useHistory()
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)
	const retailerId = searchParams.get('retailer')
	const watchesInfo = useSelector((state) => state.watches)
	const [size, setSize] = useState(0)
	const dispatch = useDispatch()

	const [numberWatches, setNumberWatches] = useState(null)
	const [actualNumber, setActualNumber] = useState(null)

	const emptyWatchInfo = {
		reference: '',
		images: [],
		originalBoxAndPapers: false,
		originalBox: false,
		originalPapers: false,
		comments: '',
	}

	useEffect(() => {
		if (location.state) {
			setNumberWatches(location.state)
			setActualNumber(1)
		}
	}, [location])

	useEffect(() => {
		if (size === numberWatches && actualNumber === numberWatches) {
			history.push({
				pathname: '/sell-now/customer',
				search: `retailer=${retailerId}`,
				state: watchesInfo,
			})
		}
	}, [size, actualNumber])

	function formatData(formData) {
		;['originalCase', 'warrantyCertificate', 'instructions'].map((entry) => {
			formData.set(entry, formData.get(entry) === 'on')
		})

		let images = []
		const watchToUpdate = watchesInfo[actualNumber - 1]
		Array.from({ length: 4 }, (_, i) => i).map((index) => {
			let entry = `file-input${index}`
			let value = formData.get(entry)
			if (value.name !== '') {
				images.push(value)
			} else if (watchToUpdate !== undefined) {
				images.push(watchToUpdate.images[index])
			}
			formData.delete(entry)
		})
		let jsonData = Object.fromEntries(formData.entries())
		jsonData['images'] = images
		return jsonData
	}

	function handleNext(event) {
		event.preventDefault()
		const formData = new FormData(event.target)
		let jsonData = formatData(formData)

		if (watchesInfo[actualNumber - 1] !== undefined) {
			dispatch(updateWatch(jsonData, actualNumber - 1))
			setSize(actualNumber)
		} else {
			dispatch(addWatch(jsonData))
			setSize(size + 1)
		}
		if (actualNumber + 1 <= numberWatches) {
			setActualNumber(actualNumber + 1)
		}
		event.target.reset()
	}

	function handlePrev(event) {
		event.preventDefault()
		if (actualNumber - 1 <= numberWatches && actualNumber - 1 > 0) {
			setActualNumber(actualNumber - 1)
		} else {
			history.goBack()
		}
	}

	if (!numberWatches) return <div />
	return (
		<>
			<AddWatchContainer
				number={actualNumber}
				handleNext={handleNext}
				handlePrev={handlePrev}
				watchInfo={
					watchesInfo[actualNumber - 1] !== undefined
						? watchesInfo[actualNumber - 1]
						: emptyWatchInfo
				}
			/>
		</>
	)
}
