import { /*baseClient,*/ API, authClient } from '../../utils/index';

// Save call
export const postCall = async (call) => {
	return authClient().post(`${API}/calls`, call);
};

// sends an email to inform of the missed call
export const sendMissedCallEmail = async (id) => {
	return authClient().get(`${API}/calls/send-missed-call-email/${id}`);
};
