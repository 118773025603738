import React, { useState, useEffect } from 'react';
import { fetchAbout } from '../../api/about';

export default function About() {
	const [about, setAbout] = useState([]);
	useEffect(() => {
		fetchAbout()
			.then((res) => {
				if (res.status === 200) {
					setAbout(res.data.text.split('\n\n'));
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	return (
		<div className='d-flex flex-column flex-root'>
			<div className='d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30'>
				<h1 className='text-white margin-bottom-2'>
					About <h1 className='text-bold'>WatchUpgrade</h1>
				</h1>
				<div className='margin-horizontal'>
					{about.map((text) => (
						<p className='text-white'>{text}</p>
					))}
				</div>
			</div>
		</div>
	);
}
