import React, { useState, useEffect } from 'react'
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined'

export default function AddWatchContainer(props) {
	const { number, handleNext, handlePrev, watchInfo } = props
	const [images, setImages] = useState([])

	useEffect(() => {
		setImages(watchInfo.images)
	}, [number])

	const updateMedia = (index) => (e) => {
		let tmp = [...images]
		tmp[index] = e.target.files[0]
		setImages(tmp)
	}

	if (!number) return <div />
	return (
		<>
			<div className='row'>
				<div className='col px-4'>
					<h2 className='text-white text-start mx-2'>{'WATCH ' + number}</h2>
				</div>
			</div>
			<form className='text-start' onSubmit={handleNext}>
				<div className='container-fluid vertical-center white-container'>
					<div className='p-2 pt-4'>
						<div className='form-group m-2'>
							<label>Photos</label>
							<div className='row'>
								{Array.from({ length: 4 }, (_, i) => i).map((index) => (
									<div className='col my-1' key={index}>
										<div className='image-upload'>
											<label htmlFor={'file-input' + index}>
												<div className='upload-icon'>
													{!images[index] ? (
														<PhotoCameraOutlinedIcon
															style={{ height: 'auto', width: 'auto' }}
														/>
													) : (
														<img
															className='icon'
															src={URL.createObjectURL(images[index])}
															alt=''
														/>
													)}
												</div>
											</label>
											<input
												id={'file-input' + index}
												name={'file-input' + index}
												type='file'
												accept='image/*'
												style={{ display: 'none' }}
												onChange={updateMedia(index)}
												required={index <= -1} //change <= 3
											/>
										</div>
									</div>
								))}
							</div>
						</div>
						<div className='form-group m-2 my-4'>
							<label htmlFor='reference'>Reference (Optional)</label>
							<input
								type='text'
								className='form-control'
								id='reference'
								name='reference'
								defaultValue={watchInfo.reference}
							/>
						</div>
						<div className='form-check form-switch m-2 p-0 my-3'>
							<input
								className='form-check-input my-0'
								type='checkbox'
								id='originalBoxAndPapers'
								name='originalCase'
								defaultChecked={watchInfo.originalCase === 'true'}
							/>
							<label
								htmlFor='originalBoxAndPapers'
								className='form-check-label'>
								Box available
							</label>
						</div>
						<div className='form-check form-switch m-2 p-0 my-3'>
							<input
								className='form-check-input my-0'
								type='checkbox'
								id='originalBox'
								name='warrantyCertificate'
								defaultChecked={watchInfo.warrantyCertificate === 'true'}
							/>
							<label htmlFor='originalBox' className='form-check-label'>
								Warranty
							</label>
						</div>
						<div className='form-check form-switch m-2 p-0 my-3'>
							<input
								className='form-check-input my-0'
								type='checkbox'
								id='originalPapers'
								name='instructions'
								defaultChecked={watchInfo.instructions === 'true'}
							/>
							<label htmlFor='originalPapers' className='form-check-label'>
								Instructions
							</label>
						</div>
						<div className='form-group m-2 my-4'>
							<label htmlFor='reference'>Additional Comments (Optional)</label>
							<input
								type='text'
								className='form-control'
								id='comments'
								name='comments'
								defaultValue={watchInfo.comments}
							/>
						</div>
					</div>
				</div>
				<div className='row justify-content-center m-2 pt-2'>
					<div className='col-5'>
						<button
							type='button'
							className='custom-button white-button black-text'
							onClick={handlePrev}>
							BACK
						</button>
					</div>
					<div className='col-5'>
						<button
							type='submit'
							className='custom-button white-button black-text'>
							NEXT
						</button>
					</div>
				</div>
			</form>
		</>
	)
}
