export const ADD_WATCH = 'ADD_WATCH';
export const UPDATE_WATCH = 'UPDATE_WATCH';
export const CLEAR_WATCHES = 'CLEAR_WATCHES';
export const SET_RETAILER = 'SET_RETAILER';

export function addWatch(watchToAdd) {
	return {
		type: ADD_WATCH,
		watchToAdd,
	};
}
export function updateWatch(watchToUpdate, idx) {
	return {
		type: UPDATE_WATCH,
		watchToUpdate,
		idx,
	};
}

export function clearWatches() {
	return {
		type: CLEAR_WATCHES,
	};
}

export function setRetailer(retailer) {
	return {
		type: SET_RETAILER,
		retailer,
	};
}
