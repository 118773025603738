import React, { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { alertError } from '../../utils/logger';
import { useLocation } from 'react-router-dom';

export default function ScheduleACall() {
	const history = useHistory();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const retailerId = searchParams.get('retailer');
	const [numberWatches, setNumberWatches] = useState(null);

	return (
		<>
			<div className='container-fluid h-75vh'>
				<div className='row justify-content-center h-100 align-content-center flex-wrap'>
					<div className='col-10'>
						<h2 className='text-white'>
							HOW MANY WATCHES DO YOU WANT TO SELL?
						</h2>
					</div>
					<div className='col-12 my-4'>
						{Array.from({ length: 10 }, (_, i) => i + 1).map((item) => (
							<button
								onClick={() => setNumberWatches(item)}
								key={item}
								className={
									numberWatches === item
										? 'button-number-watches-active button-number-watches col-2 m-1'
										: 'button-number-watches col-2 m-1'
								}>
								{item}
							</button>
						))}
					</div>
					<div className='col-8 my-5'>
						<button
							onClick={() =>
								numberWatches
									? history.push({
											pathname: `/sell-now/watches`,
											search: `retailer=${retailerId}`,
											state: numberWatches,
									  })
									: alertError(null, 'Please select a number!')
							}
							className='custom-button white-button black-text'>
							NEXT
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
