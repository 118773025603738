import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { alertError, alertNoBuyers } from '../../utils/logger';
import socket from '../../utils/socket';
import { useSelector } from 'react-redux';
import { ImportExport, FlipCameraIos, CallEnd } from '@material-ui/icons/';
import { postCall, sendMissedCallEmail } from '../../api/calls';

const { RTCPeerConnection, RTCSessionDescription } = window;
let localStream = null;
let localVideo = null;
let remoteVideo = null;
let inCall = false;
let outboundConnection = null;
let callId = null;

const configuration = {
	iceServers: [
		{
			urls: 'stun:openrelay.metered.ca:80',
		},
		{
			urls: 'turn:api.watchupgrade.com:3478',
			username: 'watch',
			credential: 'watchupgrade',
		},
		{
			urls: 'turn:api.watchupgrade.com:3478?transport=tcp',
			username: 'watch',
			credential: 'watchupgrade',
		},
	],
};

const addICERetry = async (connection, ice, type = '') => {
	let attempts = 0;
	while (attempts < 10) {
		try {
			await connection.addIceCandidate(ice);
			console.log(`ICE OK ${type}:\n${ice?.candidate || ice}`);
			return true;
		} catch (e) {
			attempts++;
			if (attempts === 10) {
				return console.log(`ICE KO ${type}:\n${ice?.candidate || ice}`);
			}
			console.log('ICE FAIL. Retrying...');
			await new Promise((r) => setTimeout(r, 1000));
		}
	}
};

export default function SellNow() {
	const history = useHistory();
	const retailer = useSelector((state) => state.retailer);
	const [receivesRemoteVideo, setReceivesRemoteVideo] = useState(false);
	const [isFront, setFront] = useState(true);
	const [focusSelf, setFocusSelf] = useState(false);

	const handleHangUp = () => {
		if (localStream) localStream.getTracks().forEach((t) => t.stop());
		localStream = null;
		if (localVideo) localVideo.srcObject = null;
		socket.close();
		history.goBack();
	};

	const handleSwitchCamera = () => {
		setFront(!isFront);
	};

	const handleSwitchFocus = () => {
		setFocusSelf(!focusSelf);
		[localVideo.srcObject, remoteVideo.srcObject] = [
			remoteVideo.srcObject,
			localVideo.srcObject,
		];
	};

	const connectWithPeer = async (peer) => {
		outboundConnection = new RTCPeerConnection(configuration);
		const handlers = {
			setIce: async (iceData) => {
				if (!iceData.inbound) return;
				await addICERetry(
					outboundConnection,
					iceData.ice.candidate,
					'(REMOTE)(OUT)'
				);
			},
			answerMade: async (data) => {
				await outboundConnection.setRemoteDescription(
					new RTCSessionDescription(data.answer)
				);
			},
			onIceCandidate: async (e) => {
				if (!e.candidate?.address) return;
				await addICERetry(outboundConnection, e.candidate, '(LOCAL)(OUT)');
				socket.emit('ice-candidate', {
					to: peer,
					ice: e.candidate,
					inbound: false,
				});
			},
			disconnect: () => {
				socket.off('set-ice', handlers.setIce);
				socket.off('answer-made', handlers.answerMade);
				outboundConnection.close();
			},
		};

		outboundConnection.onicecandidate = handlers.onIceCandidate;

		socket.on('set-ice', handlers.setIce);
		socket.on('answer-made', handlers.answerMade);
		socket.on('disconnect', handlers.disconnect);

		localStream.getTracks().forEach((track) => {
			outboundConnection.addTrack(track, localStream);
		});

		const offer = await outboundConnection.createOffer();
		await outboundConnection.setLocalDescription(
			new RTCSessionDescription(offer)
		);

		socket.emit('call-user', {
			offer,
			to: peer,
		});
		setReceivesRemoteVideo(true);
		inCall = true;
	};

	const receiveCalls = () => {
		const inboundConnection = new RTCPeerConnection(configuration);

		let peer = '';
		const handlers = {
			onTrack: ({ streams }) => {
				if (remoteVideo) {
					remoteVideo.srcObject = streams[0];
					remoteVideo.autoplay = true;
					remoteVideo.playsInline = true;
				}
			},
			setIce: async (iceData) => {
				if (iceData.inbound) return;
				await addICERetry(
					inboundConnection,
					iceData.ice.candidate,
					' (REMOTE)(IN)'
				);
			},
			onIceCandidate: async (e) => {
				if (!e.candidate?.address) return;
				await addICERetry(inboundConnection, e.candidate, '(LOCAL)(IN)');

				socket.emit('ice-candidate', {
					to: peer,
					ice: e.candidate,
					inbound: true,
				});
			},
			callMade: async (data) => {
				peer = data.socket;

				await inboundConnection.setRemoteDescription(
					new RTCSessionDescription(data.offer)
				);

				if (!inCall) {
					connectWithPeer(data.socket);
				}
				const answer = await inboundConnection.createAnswer();
				await inboundConnection.setLocalDescription(
					new RTCSessionDescription(answer)
				);
				socket.emit('make-answer', {
					to: data.socket,
					answer,
				});
			},
			peerDisconnected: () => {
				inCall = false;
				setReceivesRemoteVideo(false);
				if (remoteVideo) {
					remoteVideo.srcObject = null;
				}
				handleHangUp();
			},
			disconnect: () => {
				inboundConnection.close();
			},
		};

		inboundConnection.ontrack = handlers.onTrack;
		inboundConnection.onicecandidate = handlers.onIceCandidate;

		socket.on('set-ice', handlers.setIce);
		socket.on('call-made', handlers.callMade);
		socket.on('peer-disconnected', handlers.peerDisconnected);
		socket.on('disconnect', handlers.disconnect);
	};

	const initStreams = async () => {
		try {
			localStream = await navigator.mediaDevices.getUserMedia({
				audio: true,
				video: {
					width: 640,
					height: 480,
					frameRate: 30,
					facingMode: 'user',
				},
			});
		} catch (e) {
			console.log(e);
			alertError(null, 'No camera found');
		}
		if (localVideo) {
			localVideo.srcObject = localStream;
			localVideo.playsInline = true;
		}
		receiveCalls();
	};

	useEffect(() => {
		postCall({ date: new Date(), retailer: retailer._id })
			.then((res) => {
				if (res.status === 201) {
					callId = res.data._id;
					socket.connect();
					socket.emit('init', {
						type: 'client',
						retailer: retailer.name,
						country: retailer.country,
						call_id: res.data._id,
					});
					initStreams();
					remoteVideo = document.getElementById('remote-video');
					localVideo = document.getElementById('local-video');
				}
			})
			.catch((error) => {});
		return () => {
			if (localStream) localStream.getTracks().forEach((t) => t.stop());
			socket.disconnect();
			//socket.off('init');
			socket.off('call-made');
			socket.off('peer-disconnected');
			socket.off('answer-made');
			socket.off('set-ice');
		};
	}, []);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			if (!inCall) {
				if (localStream) localStream.getTracks().forEach((t) => t.stop());
				socket.disconnect();
				//socket.off('init');
				socket.off('call-made');
				socket.off('peer-disconnected');
				socket.off('answer-made');
				socket.off('set-ice');
				sendMissedCallEmail(callId).catch((err) => {});
				alertNoBuyers().then(() => {
					history.goBack();
				});
			}
		}, 45000);

		return () => {
			clearTimeout(timeoutId);
		};
	}, []);

	useEffect(async () => {
		if (!localStream) return;
		localStream.getVideoTracks().forEach(function (track) {
			track.stop();
		});
		try {
			let stream = await navigator.mediaDevices.getUserMedia({
				audio: true,
				video: {
					width: 640,
					height: 480,
					frameRate: 30,
					facingMode: {
						exact: isFront ? 'user' : 'environment',
					},
				},
			});
			if (stream) {
				let videoSender = outboundConnection.getSenders().find(function (s) {
					return s.track.kind == 'video';
				});
				localStream = stream;
				localStream.getVideoTracks().forEach((track) => {
					localStream.addTrack(track, localStream);
					videoSender.replaceTrack(track);
				});
				localVideo.srcObject = localStream;
				localVideo.playsInline = true;
			}
		} catch (e) {
			console.log(e);
		}
	}, [isFront]);

	return (
		<>
			<div className='content-container row'>
				<div className='video-chat-container col-6'>
					<div className='video-container'>
						<video
							autoPlay
							muted={focusSelf}
							className='remote-video img-fluid'
							id='remote-video'></video>
						<video
							autoPlay
							muted={!focusSelf}
							className='local-video'
							id='local-video'></video>
					</div>
				</div>

				<div className='icons-container'>
					<button onClick={handleHangUp} className='icon-button red-button'>
						<CallEnd
							style={{ height: '30px', width: '30px', color: 'white' }}
						/>
					</button>
					<button
						onClick={handleSwitchCamera}
						className='icon-button blue-button'>
						<FlipCameraIos
							style={{ height: '30px', width: '30px', color: 'white' }}
						/>
					</button>
					<button
						onClick={handleSwitchFocus}
						className='icon-button white-button'>
						<ImportExport
							style={{ height: '30px', width: '30px', color: 'black' }}
						/>
					</button>
				</div>
			</div>
			{!receivesRemoteVideo && (
				<div className='center-container margin-horizontal'>
					<h2 className='text-white'>Calling ...</h2>
				</div>
			)}
		</>
	);
}
