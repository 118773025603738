import React, { useEffect } from 'react'

import { alertBlock, alertOffclock } from '../../utils/logger'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setRetailer } from '../redux/actions'
import { fetchRetailer } from '../../api/retailer/index'

export const isOffClock = () => {
	const hour = new Date().getUTCHours() + 1
	return hour < 9 || hour >= 18
}

export default function Home() {
	const history = useHistory()
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)
	const retailerId = searchParams.get('retailer')
	const dispatch = useDispatch()

	useEffect(() => {
		fetchData()
	}, [retailerId])

	const fetchData = async () => {
		try {
			const res = await fetchRetailer(retailerId)
			if (res.status === 200) {
				dispatch(setRetailer(res.data))
			}
		} catch (e) {
			alertBlock('Retailer not found')
		}
	}

	const handleSellNow = () => {
		if (isOffClock()) alertOffclock()
		else
			history.push({
				pathname: 'sell-now',
				search: `retailer=${retailerId}`,
			})
	}

	const handleFrom = () => {
		history.push({
			pathname: '/schedule-a-call',
			search: `retailer=${retailerId}`,
		})
	}

	return (
		<>
			<div className='container-fluid white-container'>
				<div className='row justify-content-center h-100 align-content-center flex-wrap'>
					<div className='col-10 my-2'>
						<button
							onClick={handleSellNow}
							className='text-white black-button custom-button'>
							CALL NOW
						</button>
					</div>
					<div className='col-10 my-2'>
						<button
							onClick={handleFrom}
							className='custom-button white-button black-text'>
							GET A VALUATION
						</button>
					</div>
				</div>
			</div>
		</>
	)
}
