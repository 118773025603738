import React, { useState } from 'react'
import { useLocation, useHistory, withRouter } from 'react-router-dom'
import { alertOffclock } from '../../utils/logger'
import logo from '../assets/logo-watchupgrade.png'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'
import { isOffClock } from '../pages/Home'

function Navbar() {
	const [menuOpen, setMenuOpen] = useState(false)
	const location = useLocation()
	const history = useHistory()
	const searchParams = new URLSearchParams(location.search)
	const retailerId = searchParams.get('retailer')

	function handleClick(e) {
		if (!menuOpen) setMenuOpen(true)
		else setMenuOpen(false)
	}

	const handleSellNow = () => {
		if (isOffClock()) alertOffclock()
		else {
			handleClick()
			history.push({
				pathname: '/sell-now',
				search: `retailer=${retailerId}`,
			})
		}
	}

	let ButtonMenu = (
		<>
			<button className='menu-button' onClick={handleClick}>
				<div>
					<MoreVertIcon style={{ 'font-size': '34px' }} />
				</div>
			</button>
			<div className={clsx('menu container', menuOpen && ' active')}>
				<div className='close position-absolute' onClick={handleClick}>
					<CloseIcon />
				</div>
				<div className='row d-flex align-content-center flex-wrap h-100'>
					<div className='col-10 offset-1 py-2'>
						<button
							className='custom-button black-button'
							onClick={handleSellNow}>
							CALL NOW
						</button>
					</div>
					<div className='col-10 offset-1 py-2'>
						<button
							className='custom-button black-button'
							onClick={() => {
								handleClick()
								history.push({
									pathname: '/schedule-a-call',
									search: `retailer=${retailerId}`,
								})
							}}>
							GET A VALUATION
						</button>
					</div>
					<div className='col-10 offset-1 py-2'>
						<button
							className='custom-button black-button'
							onClick={() => {
								handleClick()
								history.push({
									pathname: '/about-watchupgrade',
									search: `retailer=${retailerId}`,
								})
							}}>
							ABOUT WATCHUPGRADE
						</button>
					</div>
				</div>
			</div>
		</>
	)

	return (
		<nav>
			{location.pathname !== '/' ? (
				<div className='container-fluid d-flex justify-content-between pt-5 position-relative'>
					<button className='back-button' onClick={() => history.goBack()}>
						<div>
							<ArrowBackIosIcon />
							BACK
						</div>
					</button>
					{ButtonMenu}
				</div>
			) : (
				<>
					<div className='container-fluid d-flex justify-content-between p-3'>
						{ButtonMenu}
					</div>
					<img
						src={logo}
						alt='logo-watchupgrade'
						className='p-5 pt-3 img-fluid'
					/>
				</>
			)}
		</nav>
	)
}

export default withRouter(Navbar)
