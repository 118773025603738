import { baseClient, API, authClient } from '../../utils/index';

export const upload = async (data) => {
	//return baseClient().post(`${API}/file/bulk-files`, data);
	const response = await fetch(`${API}/file/single-file`, {
		method: 'POST',
		/* 		headers: { 'Content-Type': 'multipart/form-data' },
		 */ body: data,
	});
	return await response.json();
};
