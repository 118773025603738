import { baseClient, API, authClient } from '../../utils/index';

// Get all users
export const getUserRetailersByRetailer = (id) => {
	return authClient().get(`${API}/user/retailers/${id}`);
};

export const createClient = async (data) => {
	return baseClient().post(`${API}/user`, data, { userId: data.userId });
};
