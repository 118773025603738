import { combineReducers } from 'redux';
import {
	ADD_WATCH,
	UPDATE_WATCH,
	CLEAR_WATCHES,
	SET_RETAILER,
} from './actions';

const initialState = {
	retailer: null,
	watches: [],
};

function watches(state = initialState.watches, action) {
	switch (action.type) {
		case ADD_WATCH:
			return [...state, action.watchToAdd];
		case UPDATE_WATCH:
			const newState = state.map((watch, i) => {
				if (i === action.idx) {
					return action.watchToUpdate;
				}
				return watch;
			});
			return newState;
		case CLEAR_WATCHES:
			return initialState.watches;
		default:
			return state;
	}
}

function retailer(state = initialState.retailer, action) {
	switch (action.type) {
		case SET_RETAILER:
			return action.retailer;
		default:
			return state;
	}
}

export default combineReducers({ watches, retailer });
