import React, { useEffect, useState } from 'react'
import {
	useHistory,
	useLocation,
} from 'react-router-dom/cjs/react-router-dom.min'

import { createClient, getUserRetailersByRetailer } from '../../api/users'
import { createProduct } from '../../api/product'
import { createAppraisal } from '../../api/appraisal'
import { upload } from '../../api/files'
import { clearWatches } from '../redux/actions'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-phone-input-2/lib/style.css'
import { useDispatch } from 'react-redux'
import { alertError } from '../../utils/logger'
import { FormControl, MenuItem, Select } from '@material-ui/core'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
	getContentAnchorEl: () => null,
}

export const APPRAISAL_STATUS = [
	{ id: 'phone', label: 'Phone' },
	{ id: 'whatsapp', label: 'Whatsapp' },
	{ id: 'email', label: 'E-mail' },
]

export default function InfoWatches() {
	const history = useHistory()
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)
	const retailerId = searchParams.get('retailer')
	const dispatch = useDispatch()

	const [retailerUsers, setRetailerUsers] = useState([])

	const [contact, setContact] = useState({
		name: '',
		surname: '',
		email: '',
		emailConfirm: '',
		active: true,
		role: 'seller',
		createdBy: '',
	})

	const [error, showError] = useState(false)

	const handleChange = (element) => (event) => {
		setContact({ ...contact, [element]: event.target.value })
	}

	useEffect(() => {
		getUserRetailersByRetailer(retailerId)
			.then((res) => {
				if (res.status === 200) {
					setRetailerUsers(res.data)
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'No se han podido obtener los usuarios.',
				})
			})
	}, [])

	async function handleSubmit(event) {
		event.preventDefault()
		if (contact.email !== contact.emailConfirm) {
			showError(true)
			return
		} else showError(false)
		const formData = new FormData(event.target)
		Object.fromEntries(formData.entries())
		let watches = history.location.state
		const client = (
			await createClient({
				...contact,
				locale: Intl.DateTimeFormat().resolvedOptions().timeZone,
			})
		)?.data?._id

		const products = []
		const filesToUpload = []
		for (const watch of watches) {
			watch.images.forEach((img) => {
				let file = new FormData()
				file.append('file', img)
				filesToUpload.push(file)
			})
			watch.images = []

			const unresolvedPromises = filesToUpload.map((file) => upload(file))
			const results = await Promise.all(unresolvedPromises)
			results.forEach((img) => watch.images.push(img.fileURL))
			const productId = (await createProduct(watch))?.data?._id
			products.push(productId)
		}
		let createdBy = contact.createdBy
		await createAppraisal({ client, products, createdBy })?.data
		dispatch(clearWatches())
		history.push({
			pathname: '/sell-now/completed',
			search: `retailer=${retailerId}`,
		})
	}

	return (
		<>
			<div className='row'>
				<div className='col px-4'>
					<h2 className='text-white text-start mx-2'>CONTACT DATA</h2>
				</div>
			</div>
			<form className='text-start' onSubmit={handleSubmit}>
				<div
					className='container-fluid vertical-center white-container'
					style={{ height: 'inherit' }}>
					<div className='p-2 pt-3 pb-4'>
						<div className='form-group m-2'>
							<label htmlFor='name'>Name</label>
							<input
								type='text'
								className='form-control bgGrey'
								id='name'
								name='name'
								required
								value={contact.name}
								onChange={handleChange('name')}
							/>
						</div>
						<div className='form-group m-2'>
							<label htmlFor='surname'>Surname</label>
							<input
								type='text'
								className='form-control bgGrey'
								id='surname'
								name='surname'
								required
								value={contact.surname}
								onChange={handleChange('surname')}
							/>
						</div>
						<div className='form-group m-2'>
							<label htmlFor='email'>E-mail</label>
							<input
								type='email'
								className='form-control bgGrey'
								id='email'
								name='email'
								required
								value={contact.email}
								onChange={handleChange('email')}
							/>
						</div>
						<div className='form-group m-2'>
							<label htmlFor='email'>Confirm E-mail</label>
							<input
								type='email'
								className='form-control bgGrey'
								id='email'
								name='email'
								required
								value={contact.emailConfirm}
								onChange={handleChange('emailConfirm')}
							/>
						</div>
						<div className='form-group m-2 my-3'>
							<label htmlFor='email'>Who assisted you in the store?</label>
							<FormControl style={{ width: '100%' }}>
								<Select
									labelId='demo-simple-select-standard-label'
									id='demo-simple-select-standard'
									MenuProps={MenuProps}
									value={contact.createdBy}
									required
									onChange={handleChange('createdBy')}>
									{retailerUsers.map((option) => (
										<MenuItem key={option._id} value={option._id}>
											{`${option.name} ${option.surname}`}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</div>
						{error && (
							<span className='m-2'>
								E-mail confirmation doesn't match e-mail.
							</span>
						)}
					</div>
				</div>
				<div className='row justify-content-center m-2 pt-2'>
					<div className='col-5'>
						<button
							type='button'
							className='custom-button white-button black-text'
							onClick={() => history.goBack()}>
							BACK
						</button>
					</div>
					<div className='col-6'>
						<button
							type='submit'
							disabled={false}
							className='custom-button white-button black-text '>
							CONFIRM
						</button>
					</div>
				</div>
			</form>
		</>
	)
}
