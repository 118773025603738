import './App.css'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Routes } from './Routes'
import Navbar from './app/components/Navbar'

function App({basename}) {
  return (
      <BrowserRouter basename={basename}>
        <div className="App">
          <Navbar/>
          <Routes/>
        </div>
      </BrowserRouter>
  );
}

export default App;