import React from 'react'

export default function CallScheduled() {
	return (
		<div className='center-container margin-horizontal'>
			<h1 className='text-white bold'>CONGRATULATIONS!</h1>
			<h2 className='text-white'>YOUR WATCH IS BEING QUOTED</h2>
			<h2 className='text-white mt-3' style={{ fontSize: 20 }}>
				You will receive an email shortly with the valuation.
			</h2>
		</div>
	)
}
